import { createContext, Dispatch, SetStateAction } from "react";
import { SurveyForm } from "./SurveyWizard";
import { IUserSignupReason } from "src/models/Accounts/IUserSignupReason";

export type ISurveyWizardContext = {
  surveyForm: SurveyForm;
  setSurveyForm: Dispatch<SetStateAction<SurveyForm>>;
  existingSignupReasons: IUserSignupReason;
  setSignupReasonsAsync: (reasons: IUserSignupReason) => Promise<void>;
  isSetSignUpReasonsPending: boolean;
};

export const SurveyWizardContext = createContext<ISurveyWizardContext>(null!);
