export const dataProficiency = {
  Beginner: {
    name: "Beginner",
    description:
      "Beginner - I have no SQL experience and rely on tools like Excel & Power BI.",
  },
  Intermediate: {
    name: "Intermediate",
    description: "Intermediate - I understand the basics and can edit SQL.",
  },
  Expert: {
    name: "Expert",
    description: "Expert - I easily write custom SQL from scratch",
  },
};
