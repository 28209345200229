import { useContext, useState } from "react";
import { CDataCard } from "src/components/card/CDataCard";
import { CDataCardBody } from "src/components/card/CDataCardBody";
import { CDataTypography } from "src/components/text/CDataTypography";
import { SurveyWizardContext } from "../../SurveyWizardContext";
import { CDataCheckbox } from "src/components/CDataCheckbox";
import { CDataTextField } from "src/components/form/CDataTextField";
import classnames from "classnames";
import { useDebounce } from "react-use";

interface UnsupportedDriverProps {
  noDataSourcesMatchChecked: boolean;
  setNoDataSourcesMatchChecked: (noDataSourcesMatchChecked: boolean) => void;
}

export function UnsupportedDriver(props: UnsupportedDriverProps) {
  const { noDataSourcesMatchChecked, setNoDataSourcesMatchChecked } = props;

  const surveyContext = useContext(SurveyWizardContext);

  const [dataSource, setDataSource] = useState(
    surveyContext.surveyForm.unsupportedDriver,
  );

  useDebounce(
    () => {
      if (dataSource) {
        setUnsupportedDriver(dataSource);
      }
    },
    100,
    [dataSource],
  );

  const setUnsupportedDriver = (driver: string) => {
    surveyContext.setSurveyForm((previousSurveyForm) => {
      const newSurveyForm = { ...previousSurveyForm };
      newSurveyForm.unsupportedDriver = driver;
      return newSurveyForm;
    });
  };

  return (
    <CDataCard className="unsupported-driver">
      <CDataCardBody className="d-flex flex-column p-3">
        <div
          className={classnames("d-flex", {
            "mb-2": noDataSourcesMatchChecked,
          })}
        >
          <CDataCheckbox
            checked={noDataSourcesMatchChecked}
            onChange={() =>
              setNoDataSourcesMatchChecked(!noDataSourcesMatchChecked)
            }
            className="ps-0 pe-2"
          />
          <CDataTypography
            variant="typography-variant-body-medium"
            className="text-start align-content-center"
          >
            None of the data sources provided here match my use case.
          </CDataTypography>
        </div>
        {noDataSourcesMatchChecked && (
          <CDataTextField
            value={dataSource}
            onChange={(e) => setDataSource(e.target.value)}
            placeholder="What data source are you looking for?*"
            inputBaseClassName="unsupported-data-source-text-field"
          />
        )}
      </CDataCardBody>
    </CDataCard>
  );
}
