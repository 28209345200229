export const departmentsAndRoles: Record<string, string[]> = {
  Administration: [
    "Admin Manager",
    "Administrative Assistant",
    "Executive",
    "Executive Assistant",
    "Facilities Coordinator",
    "Office Manager",
    "Receptionist",
    "Travel Coordinator",
    "Other",
  ],
  "Customer Service": [
    "Call Center Representative",
    "Client Relationship Manager",
    "Customer Experience Manager",
    "Customer Success Manager",
    "Customer Support Specialist",
    "Escalation Specialist",
    "Technical Support Specialist",
    "Other",
  ],
  "Finance/Accounting": [
    "Accountant",
    "Accounts Payable Specialist",
    "Accounts Receivable Specialist",
    "Budget Analyst",
    "Chief Financial Officer (CFO)",
    "Controller",
    "Financial Analyst",
    "Payroll Specialist",
    "Tax Specialist",
    "Treasury Analyst",
    "Other",
  ],
  "Human Resources (HR)": [
    "Compensation & Benefits Analyst",
    "Diversity, Equity & Inclusion Specialist",
    "Employee Relations Specialist",
    "HR Business Partner",
    "HR Generalist",
    "HR Manager",
    "Learning & Development Specialist",
    "Recruiter",
    "Talent Acquisition Specialist",
    "Other",
  ],
  "IT/Technology": [
    "Chief Technology Officer (CTO)",
    "Cloud Engineer",
    "Cybersecurity Specialist",
    "Data Analyst",
    "Data Scientist",
    "IT Project Manager",
    "IT Support Technician",
    "Network Engineer",
    "Software Developer",
    "Systems Administrator",
    "Other",
  ],
  "Legal/Compliance": [
    "Compliance Officer",
    "Contract Manager",
    "Legal Counsel",
    "Legal Operations Specialist",
    "Paralegal",
    "Regulatory Affairs Specialist",
    "Risk Manager",
    "Other",
  ],
  Marketing: [
    "Brand Manager",
    "Content Strategist",
    "Digital Marketing Specialist",
    "Events Coordinator",
    "Graphic Designer",
    "Marketing Manager",
    "Market Research Analyst",
    "Product Marketer",
    "Social Media Manager",
    "SEO/SEM Specialist",
    "Other",
  ],
  Operations: [
    "Inventory Analyst",
    "Lean/Process Improvement Specialist",
    "Logistics Coordinator",
    "Operations Analyst",
    "Operations Manager",
    "Quality Assurance Specialist",
    "Supply Chain Specialist",
    "Procurement Manager",
    "Other",
  ],
  "Product Management": [
    "Business Analyst",
    "CPO",
    "Director of Product Management",
    "Product Manager",
    "Product Owner",
    "UI/UX Designer",
    "Other",
  ],
  "Research & Development (R&D)": [
    "Data Research Analyst",
    "Innovation Specialist",
    "Laboratory Technician",
    "Product Developer",
    "R&D Engineer",
    "R&D Manager",
    "Research Scientist",
    "Other",
  ],
  Sales: [
    "Account Executive",
    "Business Development Manager",
    "Customer Success Manager",
    "Inside Sales Specialist",
    "Key Account Manager",
    "Sales Representative",
    "Sales Manager",
    "Sales Operations Specialist",
    "Territory Manager",
    "Other",
  ],
  Other: [
    "Advisor / Board Member",
    "Consultant",
    "Crisis Manager",
    "Entrepreneur / Founder",
    "Event Planner",
    "Freelancer / Contractor",
    "Mediator / Negotiator",
    "Mentor / Coach",
    "Policy Analyst / Researcher",
    "Public Speaker / Industry Speaker",
    "Other",
  ],
};
