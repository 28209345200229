import { FormControl, Select } from "@mui/material";
// TODO: CLOUD-13933: Create CDBadge and replace this
import { Badge } from "reactstrap";
import { CDataMenuItem } from "src/components/menu/CDataMenuItem";
import { CDataTypography } from "src/components/text/CDataTypography";
import { CDataCol } from "src/components/layout/CDataCol";

interface CDGridDropdownProps<T> {
  categories: Record<string, string>;
  categoryFilter: number;
  setCategoryFilter: (categoryFilter: number) => void;
  tiles: T[];
}

export function CDGridDropdown<T>(props: CDGridDropdownProps<T>) {
  const { categories, categoryFilter, setCategoryFilter, tiles } = props;

  const categoryItems = Object.keys(categories).map((key, index) => {
    let categoryCount: any;
    if (categories[key] === "All") {
      categoryCount = tiles.length;
    } else {
      categoryCount = tiles.filter((currData: any) => {
        return currData.category === Object.keys(categories)[index];
      }).length;
    }
    return (
      <CDataMenuItem value={index} key={key}>
        <div className="drop-down-item">
          <CDataTypography
            variant="typography-variant-body-bold"
            className="d-inline align-text-top"
          >
            {categories[key]}
          </CDataTypography>
          <Badge className="drop-down-count-badge" color="">
            {categoryCount}
          </Badge>
        </div>
      </CDataMenuItem>
    );
  });

  return (
    <CDataCol className="cd-grid-dropdown">
      <span className="title d-inline-block">View:</span>
      <FormControl fullWidth className="drop-down-control d-inline">
        <Select
          id="demo-select-small"
          MenuProps={{
            className: "cd-grid-dropdown-open",
          }}
          value={categoryFilter}
          onChange={(event) => setCategoryFilter(event.target.value as number)}
        >
          {categoryItems}
        </Select>
      </FormControl>
    </CDataCol>
  );
}
