import classnames from "classnames";
import { CDataRow } from "src/components/layout/CDataRow";
import { CDataCol } from "src/components/layout/CDataCol";
import { CDGridDropdown } from "./CDGridDropdown";
import { CDGridFilter } from "./useCDGridFilter";
import { CDSearch } from "../CDSearch";

export enum TileWidth {
  Short = "short",
  Long = "long",
}

export type CDGridTile = {
  key?: string;
  name: string;
  category: string;
  tile: JSX.Element;
};

interface CDGridTableProps<T> {
  tiles: T[];
  tileWidth: TileWidth;
  searchHidden?: boolean;
  categories: Record<string, string>;
  emptyElement: JSX.Element;
  className?: string;
  gridFilter: CDGridFilter<T>;
}

export function CDGridTable<T extends CDGridTile>(props: CDGridTableProps<T>) {
  const {
    tiles,
    tileWidth,
    searchHidden,
    categories,
    emptyElement,
    className,
    gridFilter,
  } = props;

  const {
    filteredTiles,
    textFilter,
    setTextFilter,
    categoryFilter,
    setCategoryFilter,
  } = gridFilter;

  return (
    <div className={classnames("cd-grid-table", className)}>
      <CDataRow hidden={searchHidden} noGutters className="mt-3">
        <CDataCol className={"flex-grow-1 pe-0"}>
          <CDSearch
            value={textFilter}
            setValue={setTextFilter}
            searchPlaceholder="Search by name..."
            className="flex-grow-1"
          />
        </CDataCol>
        <CDGridDropdown
          categories={categories}
          categoryFilter={categoryFilter}
          setCategoryFilter={setCategoryFilter}
          tiles={tiles}
        />
      </CDataRow>
      {filteredTiles.length > 0 && (
        <CDataRow
          noGutters
          className={classnames("scrollable-container card-row my-3", {
            short: tileWidth === TileWidth.Short,
            long: tileWidth === TileWidth.Long,
          })}
        >
          {filteredTiles.map((card) => (
            <CDataCol key={card.key ?? card.name}>{card.tile}</CDataCol>
          ))}
        </CDataRow>
      )}
      {filteredTiles.length === 0 && emptyElement}
    </div>
  );
}
