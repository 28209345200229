import { StepWizardChildProps } from "react-step-wizard";
import { ButtonType, CDataButton } from "src/components/buttons/CDataButton";
import { AboutUserCard } from "./AboutUserCard";
import { useContext } from "react";
import { SurveyWizardContext } from "../../SurveyWizardContext";

interface IAboutUser extends Partial<StepWizardChildProps> {}

export const AboutUser = (props: IAboutUser) => {
  const surveyContext = useContext(SurveyWizardContext);

  const isNextDisabled =
    !surveyContext.surveyForm.department ||
    !surveyContext.surveyForm.role ||
    !surveyContext.surveyForm.dataProficiency;

  const onSubmit = async () => {
    await surveyContext.setSignupReasonsAsync({
      ...surveyContext.existingSignupReasons,
      surveyForm: JSON.stringify(surveyContext.surveyForm),
    });

    props.nextStep!();
  };

  return (
    <div className="about-user">
      <AboutUserCard />
      <CDataButton
        onClick={() => onSubmit()} // Passed in from the step wizard
        buttonType={ButtonType.Primary}
        disabled={isNextDisabled}
        className="next-button"
      >
        Next
      </CDataButton>
    </div>
  );
};
