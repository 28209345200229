import { useContext } from "react";
import { CDataCard } from "src/components/card/CDataCard";
import { CDataSelect } from "src/components/select/CDataSelect";
import { CDataTypography } from "src/components/text/CDataTypography";
import { SurveyWizardContext } from "../../SurveyWizardContext";
import { MenuItem } from "@mui/material";
import { departmentsAndRoles } from "./deparmentsAndRoles";
import { CDataCardBody } from "src/components/card/CDataCardBody";
import { CDataRadioGroup } from "src/components/radio/CDataRadioGroup";
import { CDataRadio } from "src/components/radio/CDataRadio";
import { dataProficiency } from "./dataProficiency";

export function AboutUserCard() {
  const surveyContext = useContext(SurveyWizardContext);

  const departments = Object.keys(departmentsAndRoles).map((key) => {
    return (
      <MenuItem key={key} value={key}>
        {key}
      </MenuItem>
    );
  });

  const roles =
    surveyContext.surveyForm.department &&
    departmentsAndRoles[surveyContext.surveyForm.department].map((role) => {
      return (
        <MenuItem key={role} value={role}>
          {role}
        </MenuItem>
      );
    });

  const setRole = (role: string) => {
    surveyContext.setSurveyForm((previousSurveyForm) => {
      const newSurveyForm = { ...previousSurveyForm };
      newSurveyForm.role = role;
      return newSurveyForm;
    });
  };

  const setDepartment = (department: string) => {
    surveyContext.setSurveyForm((previousSurveyForm) => {
      const newSurveyForm = { ...previousSurveyForm };
      newSurveyForm.department = department;
      return newSurveyForm;
    });
  };

  const setDataProficiency = (dataProficiency: string) => {
    surveyContext.setSurveyForm((previousSurveyForm) => {
      const newSurveyForm = { ...previousSurveyForm };
      newSurveyForm.dataProficiency = dataProficiency;
      return newSurveyForm;
    });
  };

  return (
    <CDataCard className="about-user-card my-4">
      <CDataCardBody>
        <CDataTypography
          variant="typography-variant-card-title"
          className="mb-3"
        >
          Tell us a little about yourself.
        </CDataTypography>
        <CDataTypography
          variant="typography-variant-body-medium"
          className="required mb-2"
        >
          {"What's your department?"}
        </CDataTypography>
        <CDataSelect
          value={surveyContext.surveyForm.department}
          onChange={(event) => {
            setRole("");
            setDepartment(event.target.value);
          }}
          className="mb-3"
          MenuProps={{
            className: "no-max-height",
          }}
        >
          {departments}
        </CDataSelect>
        <CDataTypography
          variant="typography-variant-body-medium"
          className="required mb-2"
        >
          {"What's your role?"}
        </CDataTypography>
        <CDataSelect
          value={surveyContext.surveyForm.role}
          onChange={(event) => setRole(event.target.value)}
          disabled={!surveyContext.surveyForm.department}
          className="mb-3"
          MenuProps={{
            className: "no-max-height",
          }}
        >
          {roles}
        </CDataSelect>
        <CDataTypography
          variant="typography-variant-body-medium"
          className="required mb-2"
        >
          How would you rate yourself with data proficiency?
        </CDataTypography>
        <CDataRadioGroup
          value={surveyContext.surveyForm.dataProficiency}
          onChange={(event) => setDataProficiency(event.target.value)}
        >
          <CDataRadio
            value={dataProficiency.Beginner.name}
            label={dataProficiency.Beginner.description}
            className="mb-2"
          />
          <CDataRadio
            value={dataProficiency.Intermediate.name}
            label={dataProficiency.Intermediate.description}
            className="mb-2"
          />
          <CDataRadio
            value={dataProficiency.Expert.name}
            label={dataProficiency.Expert.description}
          />
        </CDataRadioGroup>
      </CDataCardBody>
    </CDataCard>
  );
}
