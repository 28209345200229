import { useContext, useState } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { CDataButton, ButtonType } from "src/components/buttons/CDataButton";
import { SelectDataSourcesCard } from "./SelectDataSourcesCard";
import { SurveyWizardContext } from "../../SurveyWizardContext";

interface SelectDataSourcesProps extends Partial<StepWizardChildProps> {}

export const SelectDataSources = (props: SelectDataSourcesProps) => {
  const surveyContext = useContext(SurveyWizardContext);
  const [noDataSourcesMatchChecked, setNoDataSourcesMatchChecked] =
    useState(false);

  const isNextDisabled =
    surveyContext.surveyForm.selectedDrivers.length === 0 &&
    (!noDataSourcesMatchChecked || !surveyContext.surveyForm.unsupportedDriver);

  const onSubmit = async () => {
    await surveyContext.setSignupReasonsAsync({
      ...surveyContext.existingSignupReasons,
      surveyForm: JSON.stringify(surveyContext.surveyForm),
    });

    props.nextStep!();
  };

  return (
    <div className="select-data-sources">
      <SelectDataSourcesCard
        noDataSourcesMatchChecked={noDataSourcesMatchChecked}
        setNoDataSourcesMatchChecked={setNoDataSourcesMatchChecked}
      />
      <CDataButton
        onClick={() => onSubmit()} // Passed in from the step wizard
        buttonType={ButtonType.Primary}
        disabled={isNextDisabled}
        className="next-button"
      >
        Next
      </CDataButton>
    </div>
  );
};
