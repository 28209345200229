import { useContext, useState, useEffect } from "react";
import StepWizard, { StepWizardProps } from "react-step-wizard";
import { browserName } from "react-device-detect";

import { Container, Row, Col } from "reactstrap";

import Loader from "../../components/Loader";
import {
  ISelectedConnection,
  InitialSetupContext,
} from "./Setup/InitialSetupContext";

import { SelectInitialConnectionStep } from "./Setup/Steps/1-SelectConnection/SelectInitialConnectionStep";
import { AddInitialConnectionStep } from "./Setup/Steps/2-AddConnection/AddInitialConnectionStep";
import { ConfirmInitialSetup } from "./Setup/Steps/3-Confirmation/ConfirmInitialSetup";
import PostSignup from "../../components/PostSignup";
import { Footer } from "../../components/Footer";
import Main from "../../components/Main";
import Content from "../../components/Content";
import { OnboardingHeader } from "./Setup/OnboardingHeader";
import { SUPPORTED_BROWSERS } from "../../components/notification/DashboardNotificationManagerConstants";
import { initialSelectedConnection } from "./InitialSetupWizard.mocks";
import { ModalContext } from "../../routes/ModalContext";
import Slider from "../../components/slider";
import { useSessionState } from "../../hooks/useSessionState";
import EmbeddedDocumentation from "../../components/EmbeddedDocumentation";
import { isMacSafariWebView } from "../../components/notification/NotificationBarUtil";
import { SurveyWizard } from "./Survey/SurveyWizard";
import { useQuery } from "@tanstack/react-query";
import { useFlags } from "flagsmith/react";
import { getSignupReasons } from "./Survey/getSignupReasons";

export const InitialSetupWizard = () => {
  const modalContext = useContext(ModalContext);
  const sessionState = useSessionState();
  const flagsmith = useFlags(["onboarding_survey"]);

  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState<boolean[]>([]);
  const [selectedConnection, setSelectedConnection] =
    useState<ISelectedConnection>(initialSelectedConnection);
  const [preventNavigation, setPreventNavigation] = useState(false);
  const [preventNavigationTitle, setPreventNavigationTitle] = useState("");
  const [preventNavigationMessage, setPreventNavigationMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const isSurveyEnabled = flagsmith.onboarding_survey.enabled;

  const { data: existingSignupReasons, isPending: loadingSignupReasons } =
    useQuery({
      queryKey: ["/signupreasons/get"],
      queryFn: () => getSignupReasons(),
      meta: {
        errorMessage:
          "Failed to get signup reasons due to the following error:",
      },
    });

  const [surveyCompleted, setSurveyCompleted] = useState(
    existingSignupReasons?.isSurveyComplete,
  );

  useEffect(() => {
    const initializeSetupWizard = async () => {
      getBrowserName();
      // If we've already loaded the session state, skip loading it again.
      // The normal login flow should have already loaded this unless they refresh the page while on
      // the setup wizard.
      await sessionState.initializeSessionState(true);

      const completedStepsList: boolean[] = [];
      steps.forEach((_step, index) => {
        if (index === 0) {
          completedStepsList.push(true);
        } else {
          completedStepsList.push(false);
        }
      });
      setCompletedSteps(completedStepsList);

      // Set onbeforeunload so that the browser will alert the user before allowing them to refresh the page. The contents of this function are irrelevant, it just needs to not be undefined/null
      window.onbeforeunload = () => {
        return true;
      };

      setLoading(false);
    };

    initializeSetupWizard();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (
      !loadingSignupReasons &&
      existingSignupReasons &&
      existingSignupReasons.isSurveyComplete
    ) {
      setSurveyCompleted(true);
    }
  }, [existingSignupReasons]); // eslint-disable-line

  function getBrowserName() {
    if (
      !SUPPORTED_BROWSERS.includes(browserName) &&
      !isMacSafariWebView(navigator.userAgent)
    ) {
      modalContext.showUnsupportedBrowser();
    }
  }
  function updatePreventNavigation(
    preventNavigation: boolean,
    preventNavigationTitle: string,
    preventNavigationMessage: string,
  ) {
    setPreventNavigation(preventNavigation);
    setPreventNavigationTitle(preventNavigationTitle);
    setPreventNavigationMessage(preventNavigationMessage);
  }

  const onStepChange: StepWizardProps["onStepChange"] = (stats) => {
    const newCompletedSteps = [...completedSteps];
    newCompletedSteps[stats.activeStep - 1] = true;
    setCurrentStep(stats.activeStep);
    setCompletedSteps(newCompletedSteps);
  };

  const steps = [
    "Select Connection",
    "Add Connection",
    "Setup Complete",
    "Confirm Selections",
  ];

  const noTransitions = {
    enterRight: "",
    enterLeft: "",
    exitRight: "",
    exitLeft: "",
  };

  const slider = (
    <Slider
      steps={steps}
      preventNavigation={preventNavigation}
      preventNavigationTitle={preventNavigationTitle}
      preventNavigationMessage={preventNavigationMessage}
      updatePreventNavigation={updatePreventNavigation}
      restrictUnvisitedSteps={true}
      currentStep={currentStep}
      completedSteps={completedSteps}
      confirmationStep={true}
      className="mb-4"
    />
  );

  if (loading || loadingSignupReasons || !existingSignupReasons)
    return <Loader />;

  return (
    <Main className="pages-initialSetup-InitialSetupWizard">
      <OnboardingHeader
        showErrorModal={modalContext.showError}
        hideLogout={!surveyCompleted}
      />
      <Row className="flex-grow-1 gx-0">
        <Col className="connection-wizard">
          <Content>
            <Container fluid className="p-0">
              {!surveyCompleted && isSurveyEnabled ? (
                <SurveyWizard
                  existingSignupReasons={existingSignupReasons}
                  setSurveyCompleted={setSurveyCompleted}
                />
              ) : (
                <InitialSetupContext.Provider
                  value={{
                    selectedConnection: selectedConnection,
                    setSelectedConnection: setSelectedConnection,
                  }}
                >
                  <StepWizard
                    transitions={noTransitions}
                    isLazyMount={true}
                    initialStep={1}
                    onStepChange={onStepChange}
                    isHashEnabled={true}
                    nav={slider}
                  >
                    <SelectInitialConnectionStep
                      hashKey={"select-initial-connection"}
                      updateSelectedConnection={setSelectedConnection}
                    />
                    <AddInitialConnectionStep
                      hashKey={"add-initial-connection"}
                      preventNavigation={preventNavigation}
                      updatePreventNavigation={updatePreventNavigation}
                    />
                    <ConfirmInitialSetup hashKey={"confirmation"} />
                  </StepWizard>
                </InitialSetupContext.Provider>
              )}
            </Container>
          </Content>
        </Col>
        {currentStep === 2 ? (
          <EmbeddedDocumentation driver={selectedConnection.driver} />
        ) : (
          <></>
        )}
      </Row>
      <PostSignup />
      <Footer />
    </Main>
  );
};
