import { useContext, useState } from "react";
import { ButtonType, CDataButton } from "src/components/buttons/CDataButton";
import { CDataCard } from "src/components/card/CDataCard";
import { CDataCardBody } from "src/components/card/CDataCardBody";
import { CDataTextField } from "src/components/form/CDataTextField";
import { CDataTypography } from "src/components/text/CDataTypography";
import ApiConnectorLogo from "src/assets/img/Rest.svg?react";
import { SurveyWizardContext } from "../../SurveyWizardContext";
import { ToastrSuccess } from "src/services/toastrService";

export function NoResultsFound() {
  const surveyContext = useContext(SurveyWizardContext);
  const [dataSource, setDataSource] = useState<string>("");

  const updateUnsupportedDriver = async () => {
    let newDataSources = "";
    if (!surveyContext.surveyForm.unsupportedDriver) {
      newDataSources = dataSource;
    } else {
      newDataSources = `${surveyContext.surveyForm.unsupportedDriver}, ${dataSource}`;
    }

    const surveyForm = { ...surveyContext.surveyForm };
    surveyForm.unsupportedDriver = newDataSources;

    await surveyContext.setSignupReasonsAsync({
      ...surveyContext.existingSignupReasons,
      surveyForm: JSON.stringify(surveyForm),
    });

    ToastrSuccess(
      "Successfully submitted",
      "The data source you’re looking for has been successfully submitted.",
    );

    setUnsupportedDriver(newDataSources);
    setDataSource("");
  };

  const setUnsupportedDriver = (driver: string) => {
    surveyContext.setSurveyForm((previousSurveyForm) => {
      const newSurveyForm = { ...previousSurveyForm };
      newSurveyForm.unsupportedDriver = driver;
      return newSurveyForm;
    });
  };

  return (
    <div className="no-results-found text-center">
      <CDataTypography
        variant="typography-variant-headline-4"
        color="typography-color-text-muted"
        className="mb-3"
      >
        No results found.
      </CDataTypography>
      <CDataTypography
        variant="typography-variant-body-medium"
        color="typography-color-text-muted"
        className="mb-1"
      >
        Can’t find the Data Source you’re looking for?
      </CDataTypography>
      <CDataTypography
        variant="typography-variant-body-medium"
        color="typography-color-text-muted"
        className="mb-3"
      >
        Let us know by entering it below!
      </CDataTypography>
      <CDataTextField
        value={dataSource}
        onChange={(e) => setDataSource(e.target.value)}
        className="mb-2"
        inputBaseClassName="data-source-text-field"
      />
      <CDataButton
        buttonType={ButtonType.Primary}
        className="mb-3"
        onClick={updateUnsupportedDriver}
        disabled={!dataSource}
      >
        Submit
      </CDataButton>
      <CDataCard className="api-connector-card">
        <CDataCardBody className="d-flex">
          <div className="api-connector-icon-wrapper me-3">
            <ApiConnectorLogo
              className="api-connector-icon"
              aria-description="API Connector"
            />
          </div>
          <CDataTypography
            variant="typography-variant-body-medium"
            color="typography-color-medium-grey"
            className="text-start align-content-center"
          >
            You can also try our API Connector for seamless integration with
            third-party applications.
          </CDataTypography>
        </CDataCardBody>
      </CDataCard>
    </div>
  );
}
