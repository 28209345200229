import {
  ButtonType,
  CDataButton,
} from "../../../../../components/buttons/CDataButton";
import { CDataTypography } from "../../../../../components/text/CDataTypography";
import { SignupReason, SignupReasonTile } from "./SignupReasonTile";
import { Col, Row, Spinner } from "reactstrap";
import { useContext, useState } from "react";
import { useCheckboxManager } from "../../../../../components/useCheckboxManager";
import {
  CDataTooltip,
  CDataTooltipType,
} from "../../../../../components/CDataTooltip";
import { CDataCard } from "src/components/card/CDataCard";
import { CDataCardBody } from "src/components/card/CDataCardBody";
import { Container } from "@mui/material";
import { SurveyWizardContext } from "../../SurveyWizardContext";
import { signupReasonList } from "./signupReasonList";

interface ISignupReasonSelector {
  setSurveyCompleted: (completed: boolean) => void;
}

export const SignupReasonSelector = (props: ISignupReasonSelector) => {
  const { setSurveyCompleted } = props;
  const surveyContext = useContext(SurveyWizardContext);
  const checkboxManager = useCheckboxManager(signupReasonList);
  const [otherDetails, setOtherDetails] = useState("");

  const isOtherDetailsAtMax =
    checkboxManager.selectedCheckboxes.some(
      (reason) => reason.signupReason === SignupReason.Other,
    ) && otherDetails?.length > 250;

  async function handleSubmit() {
    const surveyForm = { ...surveyContext.surveyForm };

    const trimmedOtherReason = otherDetails.trim().slice(0, 250); // Just in case the user tries to bypass the length limit
    surveyForm.signupReason = checkboxManager.selectedCheckboxes.map(
      (reason) => {
        if (reason.signupReason === SignupReason.Other) {
          surveyForm.signupReasonsOther = trimmedOtherReason;
        }

        return reason.signupReason.toString();
      },
    );

    await surveyContext.setSignupReasonsAsync({
      ...surveyContext.existingSignupReasons,
      surveyForm: JSON.stringify(surveyForm),
      isSurveyComplete: true,
    });

    setSurveyCompleted(true);
  }

  const isSubmitDisabled =
    !checkboxManager.selectedCheckboxes ||
    checkboxManager.selectedCheckboxes?.length === 0 ||
    isOtherDetailsAtMax;

  const getDisabledTooltip = () => {
    if (!isSubmitDisabled) return null;
    if (isOtherDetailsAtMax) {
      return "Please limit your custom reason to 250 characters or less.";
    } else {
      return "Please make at least one selection.";
    }
  };

  const submitButton = (
    <CDataTooltip type={CDataTooltipType.Dark} title={getDisabledTooltip()}>
      <div className="d-flex justify-content-end">
        <CDataButton
          buttonType={ButtonType.Primary}
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
        >
          Complete
        </CDataButton>
      </div>
    </CDataTooltip>
  );

  return (
    <Container className="signup-reason-selector">
      <Row>
        <Col className="d-flex justify-content-center">
          <CDataCard outline roundedCorners className="selector-card">
            <CDataCardBody>
              <div hidden={!surveyContext.isSetSignUpReasonsPending}>
                <div className="loading-background" />
                <Spinner
                  className="spinner-border loading-spinner"
                  color="info"
                />
              </div>
              <div className="mb-3">
                <CDataTypography
                  variant="typography-variant-card-title"
                  color="typography-color-text-regular"
                  className="required"
                >
                  What brings you here today? (Select all that apply)
                </CDataTypography>
              </div>
              <div className="tile-container">
                {signupReasonList.map((reason) => (
                  <SignupReasonTile
                    key={reason.signupReason}
                    reason={reason}
                    toggle={checkboxManager.toggleCheckbox}
                    isChecked={checkboxManager.selectedCheckboxes.includes(
                      reason,
                    )}
                    otherDetails={otherDetails}
                    setOtherDetails={setOtherDetails}
                  />
                ))}
              </div>
            </CDataCardBody>
          </CDataCard>
        </Col>
      </Row>
      {submitButton}
    </Container>
  );
};
