import { useContext } from "react";
import { useAppSelector } from "src/redux/hooks";
import {
  compareDrivers,
  driverCategories,
} from "src/components/drivers/DriverUtilities";
import {
  CDGridTable,
  CDGridTile,
  TileWidth,
} from "src/components/tables/CDGridTable/CDGridTable";
import { CDataCard } from "src/components/card/CDataCard";
import { CDataTypography } from "src/components/text/CDataTypography";
import { DriverConnectionTile } from "src/components/drivers/DriverConnectionTile";
import { NoResultsFound } from "./NoResultsFound";
import { SurveyWizardContext } from "../../SurveyWizardContext";
import { UnsupportedDriver } from "./UnsupportedDriver";
import { useCDGridFilter } from "src/components/tables/CDGridTable/useCDGridFilter";

type SelectDataSourcesCardProps = {
  noDataSourcesMatchChecked: boolean;
  setNoDataSourcesMatchChecked: (isDataSourcesMatchChecked: boolean) => void;
};

export function SelectDataSourcesCard(props: SelectDataSourcesCardProps) {
  const { noDataSourcesMatchChecked, setNoDataSourcesMatchChecked } = props;

  const surveyContext = useContext(SurveyWizardContext);
  const driversList = useAppSelector((state) => state.driversList);

  const drivers = getDrivers();

  const dataSourceCards = createDataSourceCards();

  const gridFilter = useCDGridFilter<CDGridTile>({
    tiles: dataSourceCards,
    categories: driverCategories,
  });

  function getDrivers() {
    const mappedDrivers = driversList.drivers!.map((driver) => driver);
    const visibleDrivers = mappedDrivers.filter((driver) => !driver.hidden);

    // Push fake API Connector driver
    visibleDrivers.push({
      beta: true,
      category: "apifile",
      driver: "REST",
      hasCustomReports: false,
      hidden: false,
      niceName: "API",
      premium: true,
      isCachingDisabled: false,
      hasMetadataCache: true,
      scheduledQueryDestination: false,
      version: "1",
    });

    return visibleDrivers.sort(compareDrivers);
  }

  function createDataSourceCards() {
    if (drivers.length > 0) {
      const newDataSourceCards: CDGridTile[] = drivers.map((driver) => {
        return {
          name: driver.niceName ?? "",
          category: driver.category ?? "",
          tile: (
            <DriverConnectionTile
              isBeta={false}
              driver={driver.driver ?? ""}
              niceName={driver.niceName ?? ""}
              isSelected={surveyContext.surveyForm.selectedDrivers.includes(
                driver.niceName ?? "",
              )}
              onClick={() => onClickDataSource(driver.niceName)}
            />
          ),
        };
      });

      return newDataSourceCards;
    }

    return [];
  }

  const addDriver = (driverName: string) => {
    surveyContext.setSurveyForm((previousSurveyForm) => {
      const newSurveyForm = { ...previousSurveyForm };
      newSurveyForm.selectedDrivers = [
        ...newSurveyForm.selectedDrivers,
        driverName,
      ];
      return newSurveyForm;
    });
  };

  const removeDriver = (driverName: string) => {
    surveyContext.setSurveyForm((previousSurveyForm) => {
      const newSurveyForm = { ...previousSurveyForm };
      newSurveyForm.selectedDrivers = [...newSurveyForm.selectedDrivers].filter(
        (dataSource) => dataSource !== driverName,
      );
      return newSurveyForm;
    });
  };

  function onClickDataSource(driverName: string | undefined) {
    if (driverName) {
      if (!surveyContext.surveyForm.selectedDrivers.includes(driverName)) {
        addDriver(driverName);
      } else {
        removeDriver(driverName);
      }
    }
  }

  let adjustedHeightClass = undefined;
  if (
    surveyContext.surveyForm.selectedDrivers.length === 0 &&
    !noDataSourcesMatchChecked
  ) {
    adjustedHeightClass = "adjusted-height-1";
  } else if (
    surveyContext.surveyForm.selectedDrivers.length === 0 &&
    noDataSourcesMatchChecked
  ) {
    adjustedHeightClass = "adjusted-height-2";
  }

  return (
    <CDataCard className="select-data-sources-card">
      <CDataTypography
        className="required"
        variant="typography-variant-card-title"
      >
        What sources do you need access to? (Select all that apply)
      </CDataTypography>
      <CDGridTable
        categories={driverCategories}
        tiles={dataSourceCards}
        tileWidth={TileWidth.Short}
        emptyElement={<NoResultsFound />}
        className={adjustedHeightClass}
        gridFilter={gridFilter}
      />
      {surveyContext.surveyForm.selectedDrivers.length === 0 &&
        gridFilter.filteredTiles.length !== 0 && (
          <UnsupportedDriver
            noDataSourcesMatchChecked={noDataSourcesMatchChecked}
            setNoDataSourcesMatchChecked={setNoDataSourcesMatchChecked}
          />
        )}
    </CDataCard>
  );
}
