/// <reference types="vite-plugin-svgr/client" />
import { Col, Container, Row } from "reactstrap";
import { goToDashboard } from "../../../goToDashboard";
import { Card, CardContent } from "@mui/material";
import {
  ButtonType,
  CDataButton,
} from "../../../../../components/buttons/CDataButton";
import { useAPI } from "../../../../../components/useAPI";
import { useNavigate } from "react-router-dom";
import { CDataTypography } from "../../../../../components/text/CDataTypography";
import { RequestType } from "../../../../../components/withAPI";

export interface IConfirmInitialSetupProps {
  hashKey: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ConfirmInitialSetup = (props: IConfirmInitialSetupProps) => {
  const api = useAPI();
  const navigate = useNavigate();

  async function completeSetupAndNavigate(destination: string) {
    // Mark initial setup as complete on the account
    const response = await api.callAPI(
      RequestType.Put,
      "/account/setIsSetupFinished",
      "Failed to mark initial setup as complete.",
    );

    if (response.status === 200) {
      navigate(destination);
    } else {
      console.error(response.error ?? "Initial setup completion failed.");
    }
  }

  return (
    <Container
      className={`p-0 pages-initialSetup-components-Steps-5-Confirmation-ConfirmInitialSetup`}
    >
      <div className="confirmation-complete-container">
        <Row className="mb-3">
          <CDataTypography
            variant="typography-variant-headline-1"
            className="text-center setup-wizard-title"
          >
            Congratulations, you’ve setup your first connection!
          </CDataTypography>
        </Row>
        <Row className="mb-4">
          <Col className="text-center">
            <CDataTypography color="typography-color-medium-grey">
              You are now ready to start making the most of CData Connect Cloud.
            </CDataTypography>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col className="text-center">
            <CDataTypography variant="typography-variant-headline-2">
              What would you like to do next?
            </CDataTypography>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="next-step-card">
              <CardContent>
                <Row>
                  <span className="icon-bg">
                    <i className="fa fa-magnifying-glass fa-lg icon"></i>
                  </span>
                </Row>
                <div className="h3 card-header">Explore Data</div>
                <CDataTypography
                  color="typography-color-medium-grey"
                  className="card-text"
                >
                  View your connections data and begin customizing, saving and
                  scheduling queries directly from within Connect Cloud.
                </CDataTypography>
                <CDataButton
                  buttonType={ButtonType.Primary}
                  onClick={() => completeSetupAndNavigate("/data-explorer")}
                >
                  Data Explorer
                </CDataButton>
              </CardContent>
            </Card>
          </Col>
          <Col>
            <Card className="next-step-card">
              <CardContent>
                <Row>
                  <span className="icon-bg">
                    <i className="fa fa-chart-column fa-lg icon"></i>
                  </span>
                </Row>
                <CDataTypography
                  variant="typography-variant-headline-3"
                  className="card-header"
                >
                  Virtualization
                </CDataTypography>{" "}
                <div className="align-middle card-text">
                  Connect from dozens of out-of-the-box consumers to virtualize
                  your data in just a few clicks.
                </div>
                <CDataButton
                  buttonType={ButtonType.Primary}
                  onClick={() => completeSetupAndNavigate("/clients")}
                >
                  Client Tools
                </CDataButton>
              </CardContent>
            </Card>
          </Col>
          <Col>
            <Card className="next-step-card">
              <CardContent>
                <Row>
                  <span className="icon-bg">
                    <i className="fa fa-folder fa-lg icon"></i>
                  </span>
                </Row>
                <CDataTypography
                  variant="typography-variant-headline-3"
                  className="card-header"
                >
                  Organize
                </CDataTypography>
                <div className="align-middle card-text">
                  Set up your first Workspace to begin organizing your data with
                  access controls across all users.
                </div>
                <CDataButton
                  buttonType={ButtonType.Primary}
                  onClick={() => completeSetupAndNavigate("/datasets")}
                >
                  Virtual Datasets
                </CDataButton>
              </CardContent>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col className="text-center mt-2">
            <CDataTypography color="typography-color-medium-grey">
              Or you can simply browse Connect Cloud by going to the Dashboard.
            </CDataTypography>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <CDataButton
              buttonType={ButtonType.PrimaryOutline}
              className="primary-outline"
              onClick={() => {
                // Set onbeforeunload to null to prevent the page from showing a "Are you sure you want to leave?" alert
                window.onbeforeunload = null;
                window.DD_RUM?.addAction("go to dashboard on step-3 clicked");
                goToDashboard(api.callAPI);
              }}
              data-dd-action-name="go-to-dashboard-on-step-3"
            >
              <span>Go to Dashboard</span>
              <i className="fa-solid fa-arrow-right fa-md ms-2 align-middle" />
            </CDataButton>
          </Col>
        </Row>
      </div>
    </Container>
  );
};
